<template>
  <v-card flat>
    <v-card-text>
      <v-expand-transition v-if="showSocial">
        <social-login
          :is-login="false"
          @connected="connected"
        />
      </v-expand-transition>
      <v-expand-transition v-else>
        <v-form
          ref="registerForm"
          v-model="valid"
          lazy-validation
          @submit.prevent="onSubmit"
        >
          <div class="mb-4">
            <p class="text-14 mb-1">
              {{ $t("Nom d'affichage") }}
            </p>
            <v-text-field
              v-model="name"
              :rules="nameRules"
              placeholder="John Doe"
              outlined
              dense
              hide-details="auto"
              class="mb-4"
            />
          </div>
          <!--            <div class="mb-4">
                        <p class="text-14 mb-1">
                          {{ $t('Prénom') }}
                        </p>
                        <v-text-field
                          v-model="first_name"
                          :rules="firstNameRules"
                          placeholder="John"
                          outlined
                          dense
                          hide-details="auto"
                          class="mb-4"
                        />
                      </div>
                      <div class="mb-4">
                        <p class="text-14 mb-1">
                          {{ $t('Nom') }}
                        </p>
                        <v-text-field
                          v-model="last_name"
                          :rules="lastNameRules"
                          placeholder="Doe"
                          outlined
                          dense
                          hide-details="auto"
                          class="mb-4"
                        />
                      </div>-->
          <div class="mb-4">
            <p class="text-14 mb-1">
              {{ $t('Email') }}
            </p>
            <v-text-field
              v-model="email"
              placeholder="example@mail.com"
              outlined
              :error-messages="errorMessages.email"
              :rules="emailRules"
              :label="$t('Email')"
              dense
              hide-details="auto"
              class="mb-4"
            />
          </div>
          <div class="mb-4">
            <p class="text-14 mb-1">
              {{ $t("Mot de passe") }}
            </p>
            <v-text-field
              v-model="password"
              :type="isPasswordVisible ? 'text' : 'password'"
              placeholder="*********"
              outlined
              dense
              hide-details="auto"
              class="mb-4"
              :rules="passwordRules"
              :append-icon="isPasswordVisible ? 'mdi-eye-off-outline':'mdi-eye-outline'"
              @click:append="isPasswordVisible = !isPasswordVisible"
            />
          </div>
          <!--            <div class="mb-4">
            <p class="text-14 mb-1">Confirm Password</p>
            <v-text-field
                type="password"
                placeholder="*********"
                outlined
                dense
                hide-details=""
                class="mb-4"
            ></v-text-field>
          </div>-->

          <div class="mb-4">
            <v-checkbox
              v-model="checkbox"
              :rules="termsRules"
            >
              <template v-slot:label>
                <div>
                  {{ $t("En vous inscrivant, vous acceptez les") }}

                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <span>
                        <router-link
                          target="_blank"
                          to="/info/confidentiality-policy"
                          v-on="on"
                        >
                          {{ $t("Conditions générales d'utilisation") }}
                        </router-link>, {{ $t("les") }}
                        <router-link
                          target="_blank"
                          to="/info/confidentiality-policy"
                          v-on="on"
                        >
                          {{ $t("conditions de vente liées aux vendeurs professionnels") }}
                        </router-link>, {{ $t("avoir lu la") }} <router-link
                          target="_blank"
                          to="/info/confidentiality-policy"
                          v-on="on"
                        >
                          {{ $t("Politique de confidentialité") }}
                        </router-link> {{ $t("et avoir au moins 18 ans.") }}
                      </span>
                    </template>
                    {{ $t("Ouvre dans une nouvelle fenêtre") }}
                  </v-tooltip>
                </div>
              </template>
            </v-checkbox>
          </div>

          <div class="mb-4">
            <v-btn
              :disabled="!valid"
              block
              color="primary"
              class="text-capitalize font-600"
              type="submit"
              :loading="loading"
            >
              {{ $t("Continuer") }}
            </v-btn>
          </div>
        </v-form>
      </v-expand-transition>
    </v-card-text>
    <div class="py-2">
      <div class="text-center">
        <p
          v-if="showSocial"
          class="text-center"
        >
          {{ $t("Ou inscris-toi avec") }} <span
            class="cursor-pointer primary--text text--darken-1 font-600"
            @click.stop="showSocial = !showSocial"
          >{{ $t("E-mail") }}</span>
        </p>
        <p
          v-else
          class="text-center"
        >
          {{ $t("Ou inscris-toi avec ?") }} <span
            class="cursor-pointer primary--text text--darken-1  font-600"
            @click.stop="showSocial = !showSocial"
          >{{ $t("Compte social") }}</span>
        </p>
      </div>
    </div>
  </v-card>
</template>

<script>
  import { i18n } from '@/plugins/i18n'
  import SocialLogin from '../components/SocialLogin.vue'
  import { mapMutations } from 'vuex'

  export default {
    components: { SocialLogin },
    props: {
      hideExtra: {
        type: Boolean,
        default: false,
      },
    },
    data () {
      return {
        showSocial: true,
        valid: false,
        checkbox: false,
        loading: false,
        isPasswordVisible: false,
        name: '',
        first_name: '',
        last_name: '',
        email: '',
        password: '',
        errorMessages: { name: 'Est requis' },
        termsRules: [v => !!v || i18n.t('Accepter les conditions générales')],
        nameRules: [v => !!v || i18n.t('Le nom est requis')],
        firstNameRules: [v => !!v || i18n.t('Le prénom est requis')],
        lastNameRules: [v => !!v || i18n.t('Le nom de famille est requis')],
        emailRules: [v => !!v || i18n.t('L\'e-mail est obligatoire')],
        passwordRules: [v => !!v || i18n.t('Le mot de passe est requis')],

      }
    },
    methods: {
      ...mapMutations([
        'pushItem', // map `this.increment()` to `this.$store.commit('increment')`
        'removeItem', // map `this.increment()` to `this.$store.commit('increment')`

        // `mapMutations` also supports payloads:
        'setValue', // map `this.incrementBy(amount)` to `this.$store.commit('incrementBy', amount)`
      ]),
      connected (data) {
        if (this.$route.path === '/checkout') {
          this.$emit('connected', data)
        } else {
          if (this.$route.path === '/sign-up') {
            this.$router.push('/')
          } else {
            if (data === 'welcome') {
              this.$router.push('/welcome')
            } else {
              window.location.reload()
            }
          }
        }
      },
      onSubmit () {
        const isFormValid = this.$refs.registerForm.validate()

        if (!isFormValid) return
        this.loading = true
        // eslint-disable-next-line no-undef
        axios({
          method: 'post',
          url: '/register',
          data: { name: this.name, first_name: this.first_name, last_name: this.last_name, email: this.email, password: this.password },
          // eslint-disable-next-line no-unused-vars
          validateStatus: status => true,
        }).catch(error => {
          // this.loading = false
          this.loading = false
          console.log('there was an error')
          console.log(error)
          // eslint-disable-next-line consistent-return
        }).then((response) => {
          if (response.data.error || response.data.errors || (response.data.status && response.data.status === 'false')) {
            if (response.data.errors) {
              // this.toast(this.$t('These credentials do not match our records'), this.$vuetify.theme.currentTheme.error)
              this.errorMessages = { ...response.data.errors }
            } else {
              this.toast(response.data.message, 'red')

              // this.toast(response.data.message, this.$vuetify.theme.currentTheme.error)
            }

            return false
          }

          //localStorage.setItem('userData', JSON.stringify(response.data))
          //localStorage.setItem('login', true)
          this.setValue({ type: 'user', object: response.data })
          this.setValue({ type: 'login', object: true })
          this.$emit('connected', response.data)
          // console.log('Ehhhh')
          this.$router.push('/verify_email')
          // window.location.href = '/verify_email'
        })
          .then(() => {
            this.loading = false
          })
          .catch(error => {
            this.loading = false
            // TODO: Next Update - Show notification
            console.error('Oops, Unable to Register!')
            console.log('error :>> ', error.response || '')
            // this.errorMessages = error.response.data.error
          })
      },
      toast (message, color) {
        this.isSnackbarBottomVisible = true
        this.snackBarColor = color
        this.snackBarMessage = message
      },
    },
  }
</script>

<style lang="scss" scoped>
  .sign-up-card {
    width: 500px;
    overflow: hidden;
    background-color: #fff;
    border-radius: 8px;
    margin: 2rem auto;
    box-shadow: rgb(3 0 71 / 9%) 0px 8px 45px;
    @media(max-width: 500px){
      width: 100%;
    }
    .sign-up-card-container {
      padding: 3rem 3.75rem 0px;
      @media(max-width: 500px){
        padding: 3rem 1rem 0px;
      }
    }
  }

</style>
